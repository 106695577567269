import React from 'react';
import heroImage from '../../../images/hero.svg';
import heroSmallImage from '../../../images/hero-small.svg';
import HeroText from './HeroText';

interface Props {
  title: string | React.ReactChild;
}

export default function Hero({ title }: Props) {
  return (
    <section className="container section-m-b page-m-t hero">
      <div className="row">
        <div className="col-xs-12 col-sm-9 col-md-6 col-md-offset-1 col-lg-5 col-lg-offset-1 hero__text-col">
          <HeroText title={title} />
        </div>
        <div className="col-xs-12 col-sm-3 col-md-5 col-lg-6">
          <div className="hero__graph">
            <img src={heroImage} alt="" className="hide-xs show-md" />
            <img src={heroSmallImage} alt="" className="hide-md" />
          </div>
        </div>
      </div>
    </section>
  );
}
