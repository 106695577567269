import React from 'react';
import SEO from '../components/Seo';
import HowTo from '../components/sections/HowTo';
import Quote from '../components/sections/Quote';
import OurBenefits from '../components/sections/OurBenefits';
import Value from '../components/sections/Value';
import Themes from '../components/sections/Themes';
import Hero from '../components/sections/hero/Hero';
import Questions from '../components/sections/Questions';

export default function HomePage() {
  return (
    <>
      <SEO title="Global Belief ETF" />
      <main className="page-home">
        <Hero
          title={
            <>
              <span className="h2 hero__subtitle">
                The FIS Knights of Columbus
              </span>
              Global Belief ETF <span className="h2">(KOCG)</span>
            </>
          }
        />
        <Themes />
        <Value />
        <OurBenefits />
        <Quote />
        <HowTo />
        <Questions />
      </main>
    </>
  );
}
